import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import logo from './logo.png';
import axios from "axios";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';

function App() {

  const [AppData, setAppData] = React.useState([]);

  React.useEffect(() => {
 
    axios({
      method: "get",
      url: 'https://api.lokal.app/api/appsforweb',
    })
      .then((res) => {
        setAppData(res.data);
        console.log(res.data);
      })
      .catch((e) => {
        console.log(e);
      });

  }, []);



  return (

    <Container>
      <Row>
        <Col lg={12}>

          <h1 className="text-center">
            <img src={logo} /><br />
            Besök våra appar på webben eller ladda ner dem</h1>
        </Col>
      </Row>
      <Row xs={1} md={2} lg={4}>
    {AppData.map((item) => (
        <Col>
        <Card className='mb-3'>
          <Card.Body>
            <Card.Title>
              <a className='invisible-link' href={'https://' + item.portal } >
              <img src={'https://cdn.lokal.app/uploads/' + item.icon} />
              {item.name}
              </a>
              </Card.Title>
            <Card.Text>
              En app för alla invånare i {item.shortName}.<br />

            { item.portal && ( <div><a href={'https://' + item.portal } >Besök {item.portal}</a></div> )}
            { item.itunes_link && ( <div><a href={item.itunes_link } >Ladda ner {item.name} på AppStore</a></div> )}
            { item.googleplay_link && ( <div><a href={item.googleplay_link } >Ladda ner {item.name} på Google Play</a></div> )}

            </Card.Text>

          </Card.Body>
        </Card>
        </Col>
      ))}
      </Row>
    </Container>

  );
}

export default App;
